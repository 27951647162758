@import '../../style/style.css';
.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(144, 147, 153, 0.3);
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ededed;
  display: none;
}
.about-box .code-img {
  width: 93px;
  height: 93px;
  display: inline-block;
  transition: all 0.8s;
}
.about-box .code-img:hover {
  transform: scale(2);
}
.about-box .contact {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.about-box .contact li {
  width: 50%;
}
.about-box .contact .contact-list {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ededed;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
}
.about-box .contact .contact-list .contact-title {
  font-size: 18px;
  background: #06d19b;
  color: #fff;
  width: 140px;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
}
.about-box .contact .contact-list .list {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
.about-box .contact .contact-list .list i {
  font-weight: bold;
}
.about-box .contact .contact-list .list span {
  display: block;
  font-size: 16px;
  line-height: 32px;
}
.about-box .con-title {
  font-size: 30px;
  font-weight: bold;
  padding: 20px;
  color: #06d19b;
}
.about-box .clearfix {
  margin-top: 20px;
}
.partner-box {
  width: 100%;
  padding: 0 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
