body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,button,textarea,p,blockquote,th,td { margin:0; padding:0; }
body { background:#fff; color:#000; font-size:14px; font-family: "Microsoft Yahei",Verdana, Arial, Helvetica, sans-serif; }
td,th,caption { font-size:14px; }
h1, h2, h3, h4, h5, h6 { font-weight:normal; font-size:100%; }
address, caption, cite, code, dfn, em, strong, th, var { font-style:normal; font-weight:normal;}
a { color:#555; text-decoration:none; }
img { border:none; }
ol,ul,li { list-style:none; }
input, textarea, select, button { font:14px "Microsoft Yahei", Verdana,Helvetica,Arial,sans-serif; }
table { border-collapse:collapse; }
html {overflow-y: scroll;} 
.wrap{
	margin:0 auto;
	width:1200px;
	position:relative;
}
div.padding-none{
	padding:0;
}
.clearfix:after {content: "."; display: block; height:0; clear:both; visibility: hidden;}
.clearfix { *zoom:1; }

.logo{
	float:left;
	margin:8px 0 0 34px;
}
.phone-box{
	float:right;
	margin-right:40px;
	height:48px;
	font-size: 14px;
	padding-left:30px;
	color:#06d19b;
	padding:6px 0;
	line-height: 24px;
}
.phone-box span{
	display:block;

}
.nav li{
	float: left;
	margin-right:20px;
}
.nav{
	margin-top:18px;
	float:right;
}
.nav li a{
	display:inline-block;
	font-size:12px;
	padding:0 20px;
	line-height:28px;
	text-align:center;
	color:#333333;
}
.nav li a.current{
	background:#06d19b;
	border-radius:12px;
	color:#fff; 
}
.banner{
	background:#06d19b; 
	color:#fff;
}
.banner .wrap{
	background:url(../assets/image/bg_01.jpg) no-repeat;
	height:663px;
	position:relative;
}
.banner-box{
	padding:80px 0 0 40px;
}
.radius span{
	border-radius: 50%;
	width:12px;
	height:12px;
	background:#fff;
	display:block;
	margin-bottom:10px;
}
.banner .tips{
	font-size:82px;
	font-weight:bold;
	letter-spacing:5px
}
.classify{
	padding:10px 0 20px;
	font-size:42px;
}
.classify span{
	padding:0 18px;
}
.banner p.banner-site{
	line-height:30px;
	font-style:italic
}
.banner p{
	font-size:26px;
	line-height:76px;
}
.register{
	width:230px;
	height:68px;
	text-align:center;
	line-height:68px;
	border-radius:35px;
	color:#06d19b;
	border:2px solid #ffffff;
	background:#fff;
	display:inline-block;
	font-size:24px;
	margin-top:50px;
}
.register-type{
	background:#06d19b;
	color:#fff;
	margin-left: 24px;
}
.line{
	margin-top:20px;
	width:60px;
	height:5px;
	background:#fff;
}

.login-box{
	position:absolute;
	right:0;
	bottom:20px;
	width:202px;
	height:180px;
	border-radius:10px;
	background:rgba(255,255,255,0.8);
	padding:46px 29px 0;

}
.login-box img{
	width:180px;
	display:block;
	margin:0 auto;
}
.login-box input{
	display:none;
	padding:0 20px;
	width:162px;
	height:38px;
	line-height:38px;
	border-radius:5px;
	font-size:22px;
	color:#fff;
	background:#fff;
	color:#999999;
	border:none;
	margin-bottom:12px;
	font-size:16px;
	outline: none;
}
.login-btn{
	width:100%;
	height:38px;
	line-height:38px;
	border-radius:5px;
	background:#06d19b;
	text-align:center;
	font-size:20px;
	cursor:pointer;
	margin:8px 0 22px;
	display:block;
	color:#fff;
}
.user-box{
	font-size:14px;
	color:#333333;
	text-align:center;
}
.user-box a{
	color:#333333;
}
.user-box span{
	padding:0 6px;
}
.flow{
	/* background:#f2f2f2;  */
	min-width:1200px;
}
.flow .wrap{
	height:690px;
}
.flow-title{
	padding:112px 0;
}
.flow-content{
	width:100%;
	overflow:hidden;
}
.flow-content ul{
	display: flex;
	justify-content: center;
}
.flow-content ul li{
	float:left;
	width:160px;
	padding:0 70px;
	padding-top:130px;
}
.li_01{
	background:url(../assets/image/icon_01.png) no-repeat top center;
}
.li_02{
	background:url(../assets/image/icon_02.png) no-repeat top center;
}
.li_03{
	background:url(../assets/image/icon_03.png) no-repeat top center;
}
.li_04{
	background:url(../assets/image/icon_04.png) no-repeat top center;
}
.flow-name{
	text-align:center;
	font-size:16px;
	color:#333;
	line-height:40px;
}
.flow-describe{
	font-size:12px;
	color:#999999;
	line-height:20px;
}
.step{
	background:#fff;
	padding-bottom:105px; 
}
.step .wrap{
/*	background:url(../assets/image/bg_03.jpg) no-repeat;
	height:1232px;*/
	margin-bottom:25px; 
}
.step-titles{
	position:relative;
	margin:100px 0 30px;
}
.step-titles:before{
	content: '';
	position: absolute;
	top:50%;
	left:470px;
	width:46px;
	height:52px;
	margin-top:-22px;
	background:url(../assets/image/title_02.png) no-repeat;
}
.step-titles:after{
	content: '';
	position: absolute;
	top:50%;
	right:470px;
	width:46px;
	height:52px;
	margin-top:-22px;
	background:url(../assets/image/title_01.png) no-repeat;
}
.step-box{
	width:100%;
	overflow:hidden;
	position:relative;
	height:1040px;
}
.step-img{
	position:absolute;
	width:254px;
	height:207px;
	left:0;
	top:96px;
	background:url(../assets/image/step_bg.png) no-repeat; 
	z-index:300;
}
.left-bg{
	position:absolute;
	width:74px;
	height:328px;
	left:0px;
	top:535px;
	background:url(../assets/image/step_let.jpg) no-repeat; 
}
.right-bg{
	position:absolute;
	width:74px;
	height:328px;
	right:0px;
	top:202px;
	background:url(../assets/image/step_right.jpg) no-repeat; 
}
.step-box ul{
	width:105%;
}
.step-box li{
	float:left;
	width:190px;
	margin:0 65px 30px 0;
	padding:170px 30px 0;
	height:130px;
	position:relative;
}
.step-box li:after{
	content: '';
    position: absolute;
    top: 199px;
    right: -132px;
    width: 200px;
    height: 1px;
    border-bottom: 2px dashed #06d19c;
}
.step-box li.step-curent:after{
	content: '';
	width:0;
	height:0;
	border:none;
}
.step-box li.step-border:after{
	content: '';
	width:110px;
}

.step-title{
	font-size:18px;
	color:#06d19c;
	line-height:60px;
	display:block;
	text-align:center;
}
.step-border{
	line-height:30px;
	color:#999999;
	font-size:13px;
}
.step01{
	background:url(../assets/image/step_01.png) center 80px no-repeat
}
.step02{
	background:url(../assets/image/step_02.png) center 80px no-repeat
}
.step03{
	background:url(../assets/image/step_03.png) center 73px no-repeat
}
.step04{
	background:url(../assets/image/step_04.png) center 80px no-repeat
}
.step05{
	background:url(../assets/image/step_05.png) center 80px no-repeat
}
.step06{
	background:url(../assets/image/step_06.png) center 68px no-repeat
}
.step07{
	background:url(../assets/image/step_07.png) center 80px no-repeat
}
.step08{
	background:url(../assets/image/step_08.png) center 80px no-repeat
}
.step09{
	background:url(../assets/image/step_09.png) center 80px no-repeat
}
.step10{
	background:url(../assets/image/step_10.png) center 74px no-repeat
}
.step11{
	background:url(../assets/image/step_11.png) center 80px no-repeat
}



.footer{
	background:#06d19b;
	height:210px; 
	position:relative;
	min-width:1200px;
}
.footer .wrap{
	width:1100px;
	margin:0 auto;
}
.copyright{
	position:absolute;
	bottom:0;
	width:100%;
	text-align:center;
	background:rgba(51,51,51,0.4);
	color:#fff;
	line-height:48px;
}
.footer-left{
	float:left;
	padding-top:40px;
}
.footer-right{
	float:right;
	width:340px;
	height:190px;
	position:absolute;
	background:url(../assets/image/footer.png) no-repeat;
	right:0;
	top:-40px;
}
.footer-left img{
	float:left;
}
.user{
	float:left;
	margin-left:18px;
}
.phone{
	padding-left:40px;
	color:#fff;
	font-size:20px;
	line-height:26px;
	background:url(../assets/image/phone.png) no-repeat left center;
}
.footer p{
	color:#fff;
	font-size:14px;
	line-height: 22px;
}
.product-tips{
	text-indent:28px;
	color:#777777;
	font-size:14px;
	line-height:26px;
	padding:70px 0 150px; 
}
.product-banner{
	background:#06d19b;
}
.product-banner .wrap{
	height:273px;
	background:url(../assets/image/bg_04.jpg) no-repeat;
}
h3{
	color:#333333;
	font-size:34px; 
	text-align:center;
}
h4{
	color:#333333;
	font-size:16px;
	text-align:center;
}
.serve-title{
	margin-bottom:70px;
}
.serve-boox .wrap{
	margin-bottom:130px;
}
/*about*/
.about-banner{
	background:#06d19b;
}
.about-banner .wrap{
	height:273px;
	background:url(../assets/image/bg_06.jpg) no-repeat;
}
.content{
	/* padding:52px 0; */
}
.content-left{
	text-indent:32px;
	font-size:16px;
	color:#777777;
	line-height:31px;
	float:left;
	width:800px;
}
.content-left1{
	text-indent:32px;
	font-size:16px;
	color:#777777;
	line-height:31px;
	float:left;
	width:950px;
}
.content-right{
	width:337px;
	height:431px;
	float:right;
	background:url(../assets/image/about_01.png) no-repeat;
}
.about-img{
	margin-bottom:120px;
	width:100%;
	overflow:hidden;
}
.about-img ul{
	width:105%;
}
.about-img ul li{
	float:left;
	width:370px;
	height:230px;
	margin-right:45px; 
}

/*backstage*/
.state-img{
	display:block;
	margin:130px auto 0;
}
.state p{
	text-align: center;
	font-size:24px;
	color:#454b69;
}
.backstage{
 	height: 100%;
    margin: 0;
    padding: 0;
}
.backstage .footer{
	width:100%;
	position:absolute;
	bottom:0;
	left:0;
}


/*described*/
.described{
	margin-top:80px;
	padding-left:48px;
}
.described li{
	padding-bottom:75px;
	font-size:16px;
	position:relative;
}

.described li:before {
	content: '';
	position: absolute;
	top: 2px;
	left:-34px;
	width:12px;
	height:12px;
	border-radius:50%;
	background: #fff;
	border: 4px solid #06d19b;
	border-radius: 50%;
	z-index:1;
}
.described li:after {
	content: '';
	position: absolute;
	top:36px;
	left:-25px;
	width:1px;
	height:calc(100% - 46px);
	background: #c1c1c1;
}
.described li:last-child:after {
	content: '';
	width:0;
	height:0;
}

.serve-left{
	float:left;
	width:550px;
}
.serve-right img{
	padding-top:30px;
}
.serve-right{
	float:right;
	width:565px;
}
.service-title{
	font-weight:bold;
	color:#5d5d5d;
	line-height:30px;
}
.serve-content{
	color:#777777;
	line-height:30px;
}
.right-box{
	padding-bottom:38px;
}
.titles{
	color:#06d19b;
	font-size:18px;
	padding-bottom:24px;
}
.product-tips{
	height:300px;
	padding:0 40px;
	background:#f8f8f8;
	color:#555555;
	font-size:16px;
	line-height:30px;
	display:flex;
	justify-content:center;
	align-items:center;
	text-align:center;
	border-radius:10px;
	border:1px solid #e6e6e6;
	width:1118px;
	margin:10px auto 156px;
}
.contract-box{
	padding:0 40px;
	width:1120px;
	overflow:hidden;
}
.contract-box ul{
	width:110%;
}
.contract-box li{
	width:176px;
	height:226px;
	border-radius:12px;
	border:1px solid #e6e6e6;
	float:left;
	margin-right:58px;
	position:relative;
}
.contract01{
	background:#f8f8f8 url(../assets/image/product_01.png) center 50px no-repeat
}
.contract02{
	background:#f8f8f8 url(../assets/image/product_02.png) center 50px no-repeat
}
.contract03{
	background:#f8f8f8 url(../assets/image/product_03.png) center 50px no-repeat
}
.contract04{
	background:#f8f8f8 url(../assets/image/product_04.png) center 50px no-repeat
}
.contract05{
	background:#f8f8f8 url(../assets/image/product_05.png) center 50px no-repeat
}
.contract06{
	background:#f8f8f8 url(../assets/image/product_06.png) center 50px no-repeat
}
.contract07{
	background:#f8f8f8 url(../assets/image/product_07.png) center 50px no-repeat
}
.contract08{
	background:#f8f8f8 url(../assets/image/product_08.png) center 50px no-repeat
}
.contract09{
	background:#f8f8f8 url(../assets/image/product_09.png) center 50px no-repeat
}
.contract10{
	background:#f8f8f8 url(../assets/image/product_10.png) center 50px no-repeat
}
.arrows-bottom{
	height:58px;
	background:url(../assets/image/product_12.png) 1020px center  no-repeat
}
.contract-title{
	font-size:17px;
	color:#999;
	text-align:center;
	padding-top:180px;
	display:block;
}
.arrows{
	position:absolute;
	left:193px;
	top:50%;
	margin-top:-12px;
	width:32px;
	height:23px;
}
.arrows-left{
	background:url(../assets/image/product_11.png) no-repeat;
}
.arrows-right{
	background:url(../assets/image/product_13.png) no-repeat;
}

.partner{
	background:#fff;
	padding:40px 0 140px;
}
.partner-box{
	width:1050px;
	padding:0 75px;

}
.partner-box div{
	display: flex;
	align-items:center;
}
.partner-box img{
	padding-bottom:50px;
}
.partner-box span{
	padding:0 40px 50px;
	font-size:24px;
	color:#b1b1b1;
}
.beian{
	color:#fff;
	padding:0 5px;
}

/*contact*/
.contact-banner{
	background: #06d19b;
}
.contact-banner .wrap{
height: 273px;
    background: url(../assets/image/contact_01.png) no-repeat;
}
.contact-right{
	width: 337px;
    height: 431px;
    float: right;
    background: url(../assets/image/contact_02.png) no-repeat;
}

