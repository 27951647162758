.flex(@jc: flex-start,@flex-direction: row, @align-items: center) {
	display: flex;
	justify-content: @jc; //flex-start | flex-end | center | space-between | space-around
	flex-direction: @flex-direction; // row | column
	align-items: @align-items; //flex-start | flex-end | center | space-between | space-around
}

.scrollbar{
	&::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
		height: 10px;
		// display: none;
	}
	&::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
		background: rgba(144,147,153,.3);
	}
	&::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		background: #ededed;
		display: none;
	}
}