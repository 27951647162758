.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(144, 147, 153, 0.3);
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ededed;
  display: none;
}
.home .banner {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.home .banner img {
  width: 1000px;
}
.home .banner .describe {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.home .banner .describe .title {
  font-size: 40px;
  text-align: center;
}
.home .banner .describe .subtitle {
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}
.home .banner1 {
  background: #010137;
}
.home .banner2 {
  background: #070826;
}
.home h3 {
  color: #333333;
  font-size: 34px;
  text-align: center;
}
.home h4 {
  color: #333333;
  font-size: 16px;
  text-align: center;
}
.home .flow {
  min-width: 1200px;
}
.home .flow .flow-title {
  padding: 112px 0;
}
.home .flow .flow-content {
  width: 100%;
  overflow: hidden;
}
.home .flow .flow-content ul {
  display: flex;
  justify-content: center;
}
.home .flow .flow-content ul li {
  float: left;
  width: 160px;
  padding: 0 70px;
  padding-top: 130px;
}
.home .flow .li_01 {
  background: url(../../assets/image/icon_01.png) no-repeat top center;
}
.home .flow .li_02 {
  background: url(../../assets/image/icon_02.png) no-repeat top center;
}
.home .flow .li_03 {
  background: url(../../assets/image/icon_03.png) no-repeat top center;
}
.home .flow .li_04 {
  background: url(../../assets/image/icon_04.png) no-repeat top center;
}
.home .flow .flow-name {
  text-align: center;
  font-size: 16px;
  color: #333;
  line-height: 40px;
}
.home .flow .flow-describe {
  font-size: 12px;
  color: #999999;
  line-height: 20px;
}
.home .step {
  background: #fff;
}
.home .step .wrap {
  margin-bottom: 25px;
}
.home .step .serve-title {
  padding: 112px 0;
}
.home .step .contract-box {
  padding: 0 40px;
  width: 1120px;
  margin: 0 auto;
}
.home .step .contract-box ul {
  width: 110%;
  height: 228px;
}
.home .step .contract-box li {
  width: 176px;
  height: 226px;
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  float: left;
  margin-right: 58px;
  position: relative;
}
.home .step .contract01 {
  background: #f8f8f8 url(../../assets/image/product_01.png) center 50px no-repeat;
}
.home .step .contract02 {
  background: #f8f8f8 url(../../assets/image/product_02.png) center 50px no-repeat;
}
.home .step .contract03 {
  background: #f8f8f8 url(../../assets/image/product_03.png) center 50px no-repeat;
}
.home .step .contract04 {
  background: #f8f8f8 url(../../assets/image/product_04.png) center 50px no-repeat;
}
.home .step .contract05 {
  background: #f8f8f8 url(../../assets/image/product_05.png) center 50px no-repeat;
}
.home .step .contract06 {
  background: #f8f8f8 url(../../assets/image/product_06.png) center 50px no-repeat;
}
.home .step .contract07 {
  background: #f8f8f8 url(../../assets/image/product_07.png) center 50px no-repeat;
}
.home .step .contract08 {
  background: #f8f8f8 url(../../assets/image/product_08.png) center 50px no-repeat;
}
.home .step .contract09 {
  background: #f8f8f8 url(../../assets/image/product_09.png) center 50px no-repeat;
}
.home .step .contract10 {
  background: #f8f8f8 url(../../assets/image/product_10.png) center 50px no-repeat;
}
.home .step .arrows-bottom {
  height: 58px;
  background: url(../../assets/image/product_12.png) 1020px center no-repeat;
}
.home .step .contract-title {
  font-size: 17px;
  color: #999;
  text-align: center;
  padding-top: 180px;
  display: block;
}
.home .step .arrows {
  position: absolute;
  left: 193px;
  top: 50%;
  margin-top: -12px;
  width: 32px;
  height: 23px;
}
.home .step .arrows-left {
  background: url(../../assets/image/product_11.png) no-repeat;
}
.home .step .arrows-right {
  background: url(../../assets/image/product_13.png) no-repeat;
}
.home .serve-title {
  padding: 112px 0;
}
.home .partner {
  background: #fff;
}
.home .partner .partner-box {
  width: 100%;
  padding: 0 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.home .partner .partner-box div {
  display: flex;
  align-items: center;
}
.home .partner .partner-box img {
  padding-bottom: 50px;
}
.home .partner .partner-box span {
  padding: 0 40px 50px;
  font-size: 24px;
  color: #b1b1b1;
}
