/* 改变主题色变量 */
.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(144, 147, 153, 0.3);
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ededed;
  display: none;
}
.header-white {
  -moz-box-shadow: 0px 2px 5px rgba(204, 204, 204, 0.5);
  -webkit-box-shadow: 0px 2px 5px rgba(204, 204, 204, 0.5);
  box-shadow: 0px 2px 5px rgba(204, 204, 204, 0.5);
  background: #fff;
}
.header {
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: all 0.8s;
}
.header .logo {
  width: 140px;
}
.header .nav {
  margin: 0 150px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.header .nav .link {
  display: block;
  width: 100px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 10px;
}
.header .nav .link:hover {
  transition: all 0.5s;
  font-size: 18px;
}
.header .nav .link-black {
  color: black;
}
.header .nav .active {
  font-size: 18px;
}
.header .r-g {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.header .r-g .btn {
  padding: 8px 20px;
  border: 2px solid #fff;
  color: #fff;
  margin-left: 20px;
  cursor: pointer;
}
.header .r-g .btn:nth-child(1) {
  margin-left: 0;
}
.header .r-g .btn:hover {
  transition: all 0.8s;
  color: #06D19B;
  border-color: #06D19B;
}
.header .r-g .btn-black {
  color: #06D19B;
  border-color: #06D19B;
}
