.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(144, 147, 153, 0.3);
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ededed;
  display: none;
}
.footer {
  background: #06d19b;
  height: 210px;
  position: relative;
  min-width: 1200px;
}
.footer .wrap {
  width: 1100px;
  margin: 0 auto;
}
.footer .copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: rgba(51, 51, 51, 0.4);
  color: #fff;
  height: 48px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.footer .copyright .beian-icon {
  width: 20px;
  height: 20px;
}
.footer .copyright .beian {
  color: #fff;
  padding: 0 10px;
  text-decoration: none;
  font-size: 14px;
}
.footer .footer-left {
  float: left;
  padding-top: 40px;
}
.footer .footer-right {
  float: right;
  width: 340px;
  height: 190px;
  position: absolute;
  background: url(../../assets/image/footer.png) no-repeat;
  right: 120px;
  top: -40px;
}
.footer .qrcode {
  width: 90px;
  height: 90px;
}
.footer .footer-left img {
  float: left;
}
.footer p {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
}
.footer .user {
  float: left;
  margin-left: 18px;
}
.footer .phone {
  padding-left: 40px;
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  background: url(../../assets/image/phone.png) no-repeat left center;
}
